<template>
    <div class="c-intro">
        <div class="c-intro__text" v-html="comp.settings.text"></div>

        <div class="c-intro__box"></div>
        <progressive-img class="c-intro__img"
                :src="siteURL + comp.settings.img.styles[0].path"
                :placeholder="comp.settings.img.styles[1].path"
                no-ratio
        />
    </div>
</template>

<script>
  import imagesLoaded from 'vue-images-loaded'

  export default {
    name: 'Comp_intro',
    props: {
      comp: Object
    },

    directives: {
      imagesLoaded
    },

    data () {
      return {
        siteURL: null,
        imgLoaded: false
      }
    },

    created () {
      this.siteURL = process.env.VUE_APP_BACKEND_URL
    }
  }
</script>

<style scoped>

</style>
